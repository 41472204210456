import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let originPush = Router.prototype.push; //备份原push方法

Router.prototype.push = function(location, resolve, reject) {
	if (resolve && reject) {
		//如果传了回调函数，直接使用
		originPush.call(this, location, resolve, reject);
	} else {
		//如果没有传回调函数，手动添加
		originPush.call(
			this,
			location,
			() => {},
			() => {}
		);
	}
};
const routers = new Router({
	mode: "history",
	routes: [{
			path: "/",
			name: "meetLove",
			component: () => import("@/components/meetLove"),
			meta: {
				title: "遇识爱"
			},
		},
		{
			path: "/aboutUS",
			name: "aboutUS",
			component: () => import("@/components/aboutUS"),
			meta: {
				title: "遇识爱"
			},
		},
		{
			path: "/joinUS",
			name: "JoinUS",
			component: () => import("@/components/joinUS"),
			meta: {
				title: "遇识爱"
			},
		},
		{
			path: "/FConvention",
			name: "FConvention",
			component: () => import("@/components/filePage/FConvention"),
			meta: {
				title: "中国互联网视听节目服务自律公约"
			},
		},
		{
			path: "/privacyPolicy",
			name: "privacyPolicy",
			component: () => import("@/components/filePage/privacyPolicy"),
			meta: {
				title: "隐私政策"
			},
		},
		{
			path: "/userAgreement",
			name: "userAgreement",
			component: () => import("@/components/filePage/userAgreement"),
			meta: {
				title: "用户协议"
			},
		},
		{
			path: "/vipAgreement",
			name: "vipAgreement",
			component: () => import("@/components/filePage/vipAgreement"),
			meta: {
				title: "VIP会员服务协议"
			},
		},
		{
			path: "/broadCast",
			name: "broadCast",
			component: () => import("@/components/filePage/broadCast"),
		},
		{
			path: "/payType",
			name: "payType",
			component: () => import("@/components/pay/payType"),
			meta: {
				title: "遇识爱"
			},
		},
		{
			path: "/payMember",
			name: "payMember",
			component: () => import("@/components/pay/payMember"),
			meta: {
				title: "遇识爱"
			},
		},
		{
			path: "/payGold",
			name: "payGold",
			component: () => import("@/components/pay/payGold"),
			meta: {
				title: "遇识爱"
			},
		},
		{
			path: "/payAdv",
			name: "payAdv",
			component: () => import("@/components/pay/payAdv"),
			meta: {
				title: "遇识爱"
			},
		}
	],
});

// routers.beforeEach((to, from, next) => {
//   document.title = to.meta.title;
// next();
// });

routers.afterEach((to) => {
	if (to.meta.title) {
		document.title = to.meta.title;
	}
});

export default routers;