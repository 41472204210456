import Vue from "vue";
import App from "./App.vue";
import vant from "vant"
import router from "./router";
import moment from "moment"
import request from './api/request.js'

import "./css/global.less";
import "amfe-flexible/index";
import "vant/lib/index.css"

Vue.use(vant);
Vue.config.productionTip = false;
Vue.prototype.$request = request
Vue.prototype.$moment = moment
moment.locale('zh-cn')

Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
});

new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
