<template>
  <div id="app">
    <!-- <meetLove/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import meetLove from './components/meetLove.vue'

export default {
  name: 'App',
  components: {
    // meetLove
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  font-family: YouSheShaYuFeiTeJianKangTi-Regular;
}
</style>
