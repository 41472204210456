let API_URL = 'https://api.yushiai.com.cn'
import router from "@/router";
import axios from 'axios';
 const request = (url,data,method) => {
	return new Promise((resolve, reject) => {
		axios({
			method: method || 'post',
			url: API_URL + url,
			data: data,
			headers:{
				'content-type': 'application/json',
				session_token: localStorage.getItem('session_token') || ''
			}
		}).then((response) => {
			if(response.data.code==200){
				resolve(response.data)
			}else if(response.data.code==3000){
				setTimeout(()=>{
					localStorage.clear()
					let routeData = router.resolve({
						name: 'payType',
					});
					window.open(routeData.href, "_self");
				},2000)
				reject(response.data)
			}else{
				reject(response.data)
			}
		}).catch((error) => {
			reject(error)
		})
	})
}
export default request